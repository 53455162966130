import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit";
import { stringify } from "qs";
import { camelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  debounced,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { CruiseSuggestion } from "main/javascripts/types/cruiseSuggestion";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";
import { Suggestion } from "../../types/suggestion";
import { Cruise } from "../../types/cruise";

const key = "cruiseSuggestion";

const initialState: CruiseSuggestion.CruiseSuggestionEntityState = {
  cruiseOriginSuggestions: [],
  cruiseOriginInputData: null,
  cruiseDestinationSuggestions: [],
  cruiseDestinationInputData: null,
  vesselBrandSuggestions: [],
  vesselSuggestions: [],
  loading: false,
  errors: null,
};

/** Async **/
export const fetchCruiseOriginSuggestions = createAsyncThunk<
  {
    suggestions: Suggestion[];
  },
  {
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchCruiseOriginSuggestions`, async (args, { rejectWithValue }) => {
  try {
    const { params } = args;
    const { text, included_code, excluded_code } = params;
    const paramString = stringify(
      { q: text, included_code, excluded_code },
      { arrayFormat: "repeat" }
    );
    const url = `${PATH_SCOPE}/api/cruises/origins/suggests.json?${paramString}`;
    const result = await createWrapper().get(url);
    return {
      suggestions: result.data,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});
export const debouncedFetchCruiseOriginSuggestions = (arg) => (dispatch) =>
  debounced(fetchCruiseOriginSuggestions, arg, dispatch);

export const fetchCruiseDestinationSuggestions = createAsyncThunk<
  {
    suggestions: Suggestion[];
  },
  {
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(
  `${key}/fetchCruiseDestinationSuggestions`,
  async (args, { rejectWithValue }) => {
    try {
      const { params } = args;
      const { text, included_code, excluded_code } = params;
      const paramString = stringify(
        { q: text, included_code, excluded_code },
        { arrayFormat: "repeat" }
      );
      const url = `${PATH_SCOPE}/api/cruises/destinations/suggests.json?${paramString}`;
      const result = await createWrapper().get(url);
      return {
        suggestions: result.data,
      };
    } catch (err) {
      return rejectWithValue(camelizeKeys(err.response.data));
    }
  }
);
export const debouncedFetchCruiseDestinationSuggestions = (arg) => (dispatch) =>
  debounced(fetchCruiseDestinationSuggestions, arg, dispatch);

export const fetchVesselBrandSuggestions = createAsyncThunk<
  {
    vesselBrands: Cruise.VesselBrand[];
  },
  {
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchVesselBrandSuggestions`, async (args, { rejectWithValue }) => {
  try {
    const { params } = args;
    const { text, origin_destination_region_ids } = params;
    const paramString = stringify(
      { name: text, origin_destination_region_ids },
      { arrayFormat: "brackets" }
    );
    const url = `${PATH_SCOPE}/api/vessel_brands/suggests.json?${paramString}`;
    const result = await createWrapper().get(url);
    return {
      vesselBrands: result.data,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});
export const debouncedFetchVesselBrandSuggestions = (arg) => (dispatch) =>
  debounced(fetchVesselBrandSuggestions, arg, dispatch);

export const fetchVesselSuggestions = createAsyncThunk<
  {
    vessels: Cruise.Vessel[];
  },
  {
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchVesselSuggestions`, async (args, { rejectWithValue }) => {
  try {
    const { params } = args;
    const { text, origin_destination_region_ids } = params;
    const paramString = stringify(
      { name: text, origin_destination_region_ids },
      { arrayFormat: "brackets" }
    );
    const url = `${PATH_SCOPE}/api/vessels/suggests.json?${paramString}`;
    const result = await createWrapper().get(url);
    return {
      vessels: result.data,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});
export const debouncedFetchVesselSuggestions = (arg) => (dispatch) =>
  debounced(fetchVesselSuggestions, arg, dispatch);

/** slice **/
export const cruiseSuggestionSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    setCruiseOriginInputData: (
      state,
      action: PayloadAction<CruiseSuggestion.InputData>
    ) => {
      state.cruiseOriginInputData = action.payload;
    },
    setCruiseDestinationInputData: (
      state,
      action: PayloadAction<CruiseSuggestion.InputData>
    ) => {
      state.cruiseDestinationInputData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCruiseOriginSuggestions.fulfilled, (state, action) => {
        state.cruiseOriginSuggestions = action.payload.suggestions;
      })
      .addCase(fetchCruiseDestinationSuggestions.fulfilled, (state, action) => {
        state.cruiseDestinationSuggestions = action.payload.suggestions;
      })
      .addCase(fetchVesselBrandSuggestions.fulfilled, (state, action) => {
        state.vesselBrandSuggestions = action.payload.vesselBrands;
      })
      .addCase(fetchVesselSuggestions.fulfilled, (state, action) => {
        state.vesselSuggestions = action.payload.vessels;
      })
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
const stateSelector = (state: {
  [key]: CruiseSuggestion.CruiseSuggestionEntityState;
}) => state[key];

export const cruiseOriginSuggestionsSelector = createSelector(
  stateSelector,
  (state) => state.cruiseOriginSuggestions
);

export const cruiseOriginInputDataSelector = createSelector(
  stateSelector,
  (state) => state.cruiseOriginInputData
);

export const cruiseDestinationSuggestionsSelector = createSelector(
  stateSelector,
  (state) => state.cruiseDestinationSuggestions
);

export const cruiseDestinationInputDataSelector = createSelector(
  stateSelector,
  (state) => state.cruiseDestinationInputData
);

export const vesselBrandSuggestionsSelector = createSelector(
  stateSelector,
  (state) => state.vesselBrandSuggestions
);

export const vesselSuggestionsSelector = createSelector(
  stateSelector,
  (state) => state.vesselSuggestions
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

/** action export **/
export const { setCruiseOriginInputData, setCruiseDestinationInputData } =
  cruiseSuggestionSlice.actions;
