interface ISearchCategories {
  hotel: string;
  flight: string;
  dynamicPackage: string;
  cruise: string;
}

export const searchCategories: ISearchCategories = {
  hotel: "hotel",
  flight: "flight",
  dynamicPackage: "dynamicPackage",
  cruise: "cruise",
};

export type SearchCategoryValues =
  (typeof searchCategories)[keyof typeof searchCategories];
