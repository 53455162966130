import {
  createSlice,
  createSelector,
  // createAsyncThunk,
} from "@reduxjs/toolkit";
import { decamelizeKeys } from "humps";
// import { createWrapper } from "main/javascripts/api/AxiosWrapper";
// import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
// import { RootState } from "main/javascripts/store";
import { SearchCruise } from "main/javascripts/types/searchCruise";
import { cruiseIndexParamSelector } from "main/javascripts/slices/paramSlice";
import { CruiseIndexParam } from "main/javascripts/types/cruiseIndexParam";
import { isObjectEmpty } from "main/javascripts/utils/ObjectUtil";
import { clone } from "main/javascripts/utils/ObjectUtil";

const key = "searchCruise";

const initialState: SearchCruise = {
  conditionValues: {},
  advancedConditionValues: {},
  sortConditionValues: {},
  loading: false,
  errors: null,
};

/** slice **/
export const searchCruiseSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    setSearchCruiseConditionValues: (state, action) => {
      state.conditionValues = action.payload;
    },
    setSearchCruiseAdvancedConditionValues: (state, action) => {
      // read onlyエラーが発生することがあるためcloneする
      // フォームの値を直接stateに入れてしまうとimmerによりread onlyに変更され
      // 次のフォーム更新時にエラーになったと想定している
      state.advancedConditionValues = clone(action.payload || {});
    },
    setSearchCruiseSortConditionValues: (state, action) => {
      state.sortConditionValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
const stateSelector = (state: { [key]: SearchCruise }) => state[key];

export const conditionValuesSelector = createSelector(
  stateSelector,
  (state) => state.conditionValues
);

export const advancedConditionValuesSelector = createSelector(
  stateSelector,
  (state) => state.advancedConditionValues
);

export const sortConditionValuesSelector = createSelector(
  stateSelector,
  (state) => state.sortConditionValues
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

export const adjustedAdvancedValuesSelector = createSelector(
  advancedConditionValuesSelector,
  (advancedFormValues: any): any => {
    if (!advancedFormValues || isObjectEmpty(advancedFormValues)) {
      return advancedFormValues;
    }

    return {
      ...advancedFormValues,
    };
  }
);

export const advancedSearchFormInitialValuesSelector: any = createSelector(
  cruiseIndexParamSelector,
  (param: CruiseIndexParam): any => {
    const targetParams = [
      "origin_destination_region_ids",
      "origin_destination_region_names",
      "min_departure_date",
      "max_departure_date",
      "min_stays",
      "max_stays",
      "origin_region_ids",
      "origin_region_names",
      "vessel_brand_id",
      "vessel_brand_names",
    ];
    return Object.fromEntries(
      Object.entries(decamelizeKeys(param)).filter(([key]) =>
        targetParams.includes(key)
      )
    );
  }
);

/** action export **/
export const {
  setSearchCruiseConditionValues,
  setSearchCruiseAdvancedConditionValues,
  setSearchCruiseSortConditionValues,
} = searchCruiseSlice.actions;
