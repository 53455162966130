export const yupDefaultMessages = {
  mixed: {
    required: "blank",
  },
  number: {
    positive: "notAPositive",
    integer: "notAnInteger",
    min: ({ min }) => ({
      key: "greaterThanOrEqualTo",
      options: { count: min },
    }),
    max: ({ max }) => ({
      key: "lessThanOrEqualTo",
      options: { count: max },
    }),
  },
  string: {
    email: "formatInvalid",
  },
  array: {
    min: "arrayTooFew",
  },
};

// TODO
interface IFormNamespaces {
  hotel: string;
  flight: string;
  cruise: string;
  emailUser: string;
  reservation: string;
  consultation: string;
}

export const FormNamespaces: IFormNamespaces = {
  hotel: "hotel",
  flight: "flight",
  cruise: "cruise",
  emailUser: "emailUser",
  reservation: "reservation",
  consultation: "consultation",
};
