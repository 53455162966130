import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { camelizeKeys, camelize } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";
import { ConsultationState } from "main/javascripts/types/consultation";
import { clone } from "main/javascripts/utils/ObjectUtil";
import { FlightTypes } from "../../constants/FlightTypes";

const key = "consultation";

const initialState: ConsultationState = {
  originPageType: null,
  consultationCruiseCabinParams: null,
  errors: null,
  loading: false,
};

/** Async **/
export const postChatConsultations = createAsyncThunk<
  {
    consultationId: string;
    conditionId: string;
  },
  {
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/postChatConsultations`, async (args, { rejectWithValue }) => {
  try {
    const { params } = args;
    const result = await createWrapper().post(
      `${PATH_SCOPE}/api/chat_consultations.json`,
      {
        consultation: params,
      }
    );
    // console.log(result);
    return {
      consultationId: result.data.consultationId,
      conditionId: result.data.conditionId,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const postMailConsultations = createAsyncThunk<
  {
    consultationId: string;
    conditionId: string;
  },
  {
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/postMailConsultations`, async (args, { rejectWithValue }) => {
  try {
    const { params } = args;
    const result = await createWrapper().post(
      `${PATH_SCOPE}/api/mail_chat_consultations.json`,
      {
        consultation: params,
      }
    );
    // console.log(result);
    return {
      consultationId: result.data.consultationId,
      conditionId: result.data.conditionId,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const postChatSearchConsultations = createAsyncThunk<
  {
    consultationId: string;
    conditionId: string;
  },
  {
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/postChatSearchConsultations`, async (args, { rejectWithValue }) => {
  try {
    const { params } = args;
    const result = await createWrapper().post(
      `${PATH_SCOPE}/api/search_consultations.json`,
      {
        consultation: params,
      }
    );
    // console.log(result);
    return {
      consultationId: result.data.consultationId,
      conditionId: result.data.conditionId,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const postMailSearchConsultations = createAsyncThunk<
  {
    consultationId: string;
    conditionId: string;
  },
  {
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/postMailSearchConsultations`, async (args, { rejectWithValue }) => {
  try {
    const { params } = args;
    const result = await createWrapper().post(
      `${PATH_SCOPE}/api/mail_search_consultations.json`,
      {
        consultation: params,
      }
    );
    // console.log(result);
    return {
      consultationId: result.data.consultationId,
      conditionId: result.data.conditionId,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const postChatCruiseConsultations = createAsyncThunk<
  {
    consultationId: string;
    conditionId: string;
  },
  {
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/postChatCruiseConsultations`, async (args, { rejectWithValue }) => {
  try {
    const { params } = args;
    const result = await createWrapper().post(
      `${PATH_SCOPE}/api/cruise_consultations.json`,
      {
        consultation: params,
      }
    );
    // console.log(result);
    return {
      consultationId: result.data.consultationId,
      conditionId: result.data.conditionId,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const postMailCruiseConsultations = createAsyncThunk<
  {
    consultationId: string;
    conditionId: string;
  },
  {
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/postMailCruiseConsultations`, async (args, { rejectWithValue }) => {
  try {
    const { params } = args;
    const result = await createWrapper().post(
      `${PATH_SCOPE}/api/mail_cruise_consultations.json`,
      {
        consultation: params,
      }
    );
    // console.log(result);
    return {
      consultationId: result.data.consultationId,
      conditionId: result.data.conditionId,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const postChatCruiseCabinConsultations = createAsyncThunk<
  {
    consultationId: string;
    conditionId: string;
  },
  {
    cruiseUniqueId: string;
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(
  `${key}/postChatCruiseCabinConsultations`,
  async (args, { rejectWithValue }) => {
    try {
      const { cruiseUniqueId, params } = args;
      const result = await createWrapper().post(
        `${PATH_SCOPE}/api/cruises/${cruiseUniqueId}/consultations.json`,
        {
          consultation: params,
        }
      );
      // console.log(result);
      return {
        consultationId: result.data.consultationId,
        conditionId: result.data.conditionId,
      };
    } catch (err) {
      return rejectWithValue(camelizeKeys(err.response.data));
    }
  }
);

export const postMailCruiseCabinConsultations = createAsyncThunk<
  {
    consultationId: string;
    conditionId: string;
  },
  {
    cruiseUniqueId: string;
    params: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(
  `${key}/postMailCruiseCabinConsultations`,
  async (args, { rejectWithValue }) => {
    try {
      const { cruiseUniqueId, params } = args;
      const result = await createWrapper().post(
        `${PATH_SCOPE}/api/cruises/${cruiseUniqueId}/mail_consultations.json`,
        {
          consultation: params,
        }
      );
      // console.log(result);
      return {
        consultationId: result.data.consultationId,
        conditionId: result.data.conditionId,
      };
    } catch (err) {
      return rejectWithValue(camelizeKeys(err.response.data));
    }
  }
);

/** slice **/
export const consultationSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    initSearchType: (state, action) => {
      state.originPageType = action.payload;
    },
    initConsultationCruiseCabinParams: (state, action) => {
      state.consultationCruiseCabinParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
const stateSelector = (state: { [key]: ConsultationState }) => state[key];

export const originPageTypeSelector = createSelector(
  stateSelector,
  (state) => state.originPageType
);

export const consultationCruiseCabinParamsSelector = createSelector(
  stateSelector,
  (state) => state.consultationCruiseCabinParams
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

export const convertConsultationFormValuesToParams = (data: any) => {
  const params = clone(data);
  if (params.is_calendar_mode) {
    params.schedule_original = `${params.departure_date} - ${params.return_date}`;
  } else {
    delete params.departure_date;
  }
  delete params.return_date;

  if (params.guests?.length > 0) {
    params.guests.map((guest, index) => {
      if (Number(guest.number_of_children) > 0) {
        params.guests[index].ages_of_children = guest.ages_of_children.map(
          (age) => age.age
        );
      } else {
        delete params.guests[index].ages_of_children;
      }
    });
  }
  return params;
};

export const convertSearchConsultationFormValuesToParams = (data: any) => {
  const params = clone(data);
  if (params.flight_type === FlightTypes.multiCity) {
    delete params.departure_date;
    delete params.return_date;
  } else if (params.flight_type === FlightTypes.oneWay) {
    delete params.return_date;
    delete params.itineraries;
  } else {
    delete params.itineraries;
  }
  if (params.guests?.length > 0) {
    params.guests.map((guest, index) => {
      if (Number(guest.number_of_children) > 0) {
        params.guests[index].ages_of_children = guest.ages_of_children.map(
          (age) => age.age
        );
      } else {
        delete params.guests[index].ages_of_children;
      }
    });
  }
  return params;
};

export const convertCruiseCabinConsultationFormValuesToParams = (
  data: any,
  t: any,
  vessel: any = undefined
) => {
  const params = clone(data);

  const consultationType = params.consultation_type;
  if (consultationType) {
    let consultationTypeText = t(
      `constant:cruiseCabinConsultationType.${camelize(consultationType)}`
    );
    if (vessel) {
      consultationTypeText = `${vessel.name}の${consultationTypeText}`;
    }
    params.hope = `${consultationTypeText}\n${params.hope}`;
  }
  delete params.consultation_type;

  return params;
};

export const convertSearchFormParamsToFormValues = (data: any) => {
  // ホテルは現状DPの場合のみ相談フォームに遷移させるので
  // searchTypeもDP(デフォルト)とする
  const values = clone(data);
  delete values.id;
  if (values.traveler) {
    values.guests = [
      {
        numberOfAdult: values.traveler.numberOfAdult,
        numberOfChildren: values.traveler.numberOfChildren,
        agesOfChildren: values.traveler.agesOfChildren,
      },
    ];
    delete values.traveler;
    // travelerはフライト検索時のみ含まれる
    values.searchType = "flight";
  }
  if (values.guests) {
    values.numberOfRooms = values.guests.length;
    values.guests = values.guests.map((guest) => {
      return {
        numberOfAdult: guest.numberOfAdult,
        numberOfChildren: guest.agesOfChildren
          ? guest.agesOfChildren.length
          : 0,
        agesOfChildren: guest.agesOfChildren?.map((age) => {
          return { age: age };
        }),
      };
    });
  }
  return values;
};

/** action export **/
export const { initSearchType, initConsultationCruiseCabinParams } =
  consultationSlice.actions;
