import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { stringify } from "qs";
import { camelizeKeys, decamelizeKeys } from "humps";
import { format, isValid } from "date-fns";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { RootState } from "main/javascripts/store";
import { Cruise } from "main/javascripts/types/cruise";
import { clone } from "main/javascripts/utils/ObjectUtil";
import { removeEmpty } from "main/javascripts/utils/ObjectUtil";
import { parseDate } from "main/javascripts/utils/DateUtil";

const key = "cruise";
const adapter = createEntityAdapter<Cruise.Cruise>();

const initialState = adapter.getInitialState({
  cruise: null,
  page: 0,
  numPerPage: 0,
  totalNum: 0,

  isImageModalDisplayed: false,
  isSearchFormDisplayed: false,
  isDetailModalDisplayed: false,
  isSchedulePickerDisplayed: false,
  isGuestPickerDisplayed: false,

  loading: false,
  errors: null,
});

/** Async **/
export const fetchCruises = createAsyncThunk<
  {
    cruises: Cruise.Cruise[];
    totalNum: number;
    page: number;
    numPerPage: number;
  },
  {
    params?: any;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchCruises`, async (args, { rejectWithValue }) => {
  try {
    const { params, isServerSide = false, headers } = args;
    const paramString = stringify(params, { arrayFormat: "brackets" });
    const url = `/api/cruises.json?${paramString}`;
    const result = await createWrapper({ isServerSide }).get(url, {
      headers,
    });
    return {
      cruises: result.data.cruises,
      totalNum: result.data.totalNum,
      page: result.data.page,
      numPerPage: result.data.numPerPage,
    };
  } catch (err) {
    return rejectWithValue(err.data);
  }
});

export const fetchCruise = createAsyncThunk<
  {
    cruise: Cruise.Cruise;
  },
  {
    uniqueId: string;
    params: any;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/fetchCruise`, async (args, { rejectWithValue }) => {
  try {
    const { uniqueId, params, isServerSide = false, headers } = args;
    const param: string = stringify(decamelizeKeys(params), {
      arrayFormat: "brackets",
    });
    const url = `/api/cruises/${uniqueId}.json?${param}`;
    const result = await createWrapper({ isServerSide }).get(url, {
      headers,
    });
    return {
      cruise: result.data.cruise,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const searchCruiseCabins = createAsyncThunk<
  {
    cruise: Cruise.Cruise;
  },
  {
    uniqueId: string;
    params: any;
    isServerSide?: boolean;
    headers?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/searchCruiseCabins`, async (args, { rejectWithValue }) => {
  try {
    const { uniqueId, params, isServerSide = false, headers } = args;
    const url = `/api/cruises/${uniqueId}/search_cabins.json`;
    const result = await createWrapper({ isServerSide }).post(url, {
      headers,
      ...params,
    });
    return {
      cruise: result.data,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

/** slice **/
export const cruiseSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    clearCruises: (state) => {
      adapter.removeAll(state);
    },
    clearCruiseCabinRates: (state) => {
      state.cruise.rates = [];
    },
    initCruise: (state, action: PayloadAction<Cruise.Cruise>) => {
      state.cruise = action.payload;
    },
    setIsCruiseImageModalDisplayed: (state, action) => {
      state.isImageModalDisplayed = action.payload;
    },
    setCruiseSearchFormDisplayed: (state, action) => {
      state.isSearchFormDisplayed = action.payload;
    },
    setCruiseDetailModalDisplayed: (state, action) => {
      state.isDetailModalDisplayed = action.payload;
    },
    setCruiseSchedulePickerDisplayed: (state, action) => {
      state.isSchedulePickerDisplayed = action.payload;
    },
    setCruiseGuestPickerDisplayed: (state, action) => {
      state.isGuestPickerDisplayed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCruises.fulfilled, (state, action) => {
        adapter.setAll(state, action.payload.cruises);
        state.totalNum = action.payload.totalNum;
        state.page = isNaN(Number(action.payload.page))
          ? 0
          : Number(action.payload.page);
        state.numPerPage = action.payload.numPerPage;
      })
      .addCase(fetchCruise.fulfilled, (state, action) => {
        state.cruise = action.payload.cruise;
      })
      .addCase(searchCruiseCabins.fulfilled, (state, action) => {
        // 一部のデータが含まれていないためマージする
        state.cruise = { ...state.cruise, ...action.payload.cruise };
      })
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

/** selector **/
export const {
  selectById: cruiseByIdSelector,
  selectIds: cruiseIdsSelector,
  selectEntities: cruiseEntitiesSelector,
  selectAll: cruisesSelector,
  selectTotal: totalCruisesSelector,
} = adapter.getSelectors((state: RootState) => state.cruise);

const stateSelector = (state: { [key]: Cruise.CruiseEntityState }) =>
  state[key];

export const cruiseSelector = createSelector(
  stateSelector,
  (state) => state.cruise
);

export const cruiseUniqueIdSelector = createSelector(
  stateSelector,
  (state) => state.cruise.uniqueId
);

export const cruiseSchedulesSelector = createSelector(
  stateSelector,
  (state) => state.cruise.cruiseSchedules
);

export const cruiseVesselCabinsSelector = createSelector(
  stateSelector,
  (state) => state.cruise.vessel.vesselCabins
);

export const ratesSelector = createSelector(
  stateSelector,
  (state) => state.cruise.rates
);

export const hasRatesSelector = createSelector(
  stateSelector,
  (state) => state.cruise.rates && state.cruise.rates.length > 0
);

export const pageSelector = createSelector(
  stateSelector,
  (state) => state.page
);

export const numPerPageSelector = createSelector(
  stateSelector,
  (state) => state.numPerPage
);

export const totalNumSelector = createSelector(
  stateSelector,
  (state) => state.totalNum
);

export const isImageModalDisplayedSelector = createSelector(
  stateSelector,
  (state) => state.isImageModalDisplayed
);

export const isSearchFormDisplayedSelector = createSelector(
  stateSelector,
  (state) => state.isSearchFormDisplayed
);

export const isCruiseDetailModalDisplayedSelector = createSelector(
  stateSelector,
  (state) => state.isDetailModalDisplayed
);

export const isCruiseSchedulePickerDisplayedSelector = createSelector(
  stateSelector,
  (state) => state.isSchedulePickerDisplayed
);

export const isCruiseGuestPickerDisplayedSelector = createSelector(
  stateSelector,
  (state) => state.isGuestPickerDisplayed
);

export const errorsSelector = createSelector(
  stateSelector,
  (state) => state.errors
);

export const loadingSelector = createSelector(
  stateSelector,
  (state) => state.loading
);

export const vesselCabinRatesSelector = createSelector(
  [cruiseUniqueIdSelector, cruiseVesselCabinsSelector, ratesSelector],
  (cruiseUniqueId, cabins, rates): Cruise.VesselCabinRate[] => {
    if (rates?.length > 0) {
      const normalizedRates = rates.reduce((acc, cur) => {
        acc[cur.cabinCode] = cur;
        return acc;
      }, {});
      return cabins
        .map((cabin) => {
          const rate = normalizedRates[cabin.cabinCode];
          if (rate) {
            return {
              cruiseUniqueId: cruiseUniqueId,
              cabinCode: cabin.cabinCode,
              cabinCategory: cabin.cabinCategory,
              priceCents: rate.priceCents,
              vesselCabin: cabin,
              rate: rate,
            };
          } else {
            // rateが存在しないため除外
            return null;
          }
        })
        .filter((cabin) => !!cabin);
    } else {
      return cabins.map((cabin) => {
        return {
          cruiseUniqueId: cruiseUniqueId,
          cabinCode: cabin.cabinCode,
          cabinCategory: cabin.cabinCategory,
          priceCents: 0, // ratesが存在しない場合は全て0に設定している
          vesselCabin: cabin,
        };
      });
    }
  }
);

export const vesselCabinByCodeSelector = createSelector(
  [cruiseVesselCabinsSelector, (_state, cabinCode: string) => cabinCode],
  (cabins, cabinCode): Cruise.VesselCabin | undefined => {
    return cabins.find((cabin) => cabin.cabinCode === cabinCode);
  }
);

export const vesselCabinRateByCodeSelector = createSelector(
  [ratesSelector, (_state, cabinCode: string) => cabinCode],
  (rates, cabinCode): Cruise.Rate | undefined => {
    return rates?.find((rate) => rate.cabinCode === cabinCode);
  }
);

/** action export **/
export const {
  clearCruises,
  clearCruiseCabinRates,
  initCruise,
  setIsCruiseImageModalDisplayed,
  setCruiseSearchFormDisplayed,
  setCruiseDetailModalDisplayed,
  setCruiseSchedulePickerDisplayed,
  setCruiseGuestPickerDisplayed,
} = cruiseSlice.actions;

export const convertSearchCruiseFormValuesToParams = (data: any) => {
  const params = clone(data);
  delete params.origin_destination_region;
  // delete params.origin_destination_region_names;
  delete params.origin_region;
  // delete params.origin_region_names;
  delete params.vessel_brand;
  // delete params.vessel_brand_names;
  delete params.vessel;
  // delete params.vessel_names;

  if (params.min_departure_date) {
    params.min_departure_date = params.min_departure_date.replace(/\//g, "-");
  }
  if (params.max_departure_date) {
    params.max_departure_date = params.max_departure_date.replace(/\//g, "-");
  }

  return removeEmpty(params);
};

// 文字列になってしまっている値をnumber型にする
export const convertSearchCruiseFormNumericValues = (data: any) => {
  const params = clone(data);
  if (params.origin_destination_region_ids?.length > 0) {
    params.origin_destination_region_ids =
      params.origin_destination_region_ids.map((id) => {
        return Number(id);
      });
  }
  if (params.origin_region_ids?.length > 0) {
    params.origin_region_ids = params.origin_region_ids.map((id) => {
      return Number(id);
    });
  }
  if (params.vessel_brand_id?.length > 0) {
    params.vessel_brand_id = params.vessel_brand_id.map((id) => {
      return Number(id);
    });
  }
  return params;
};

export const convertSearchCruiseCabinFormValuesToParams = (data: any) => {
  const params = clone(data);
  if (params.guests?.length > 0) {
    params.guests.map((guest, index) => {
      if (Number(guest.number_of_children) > 0) {
        params.guests[index].ages_of_children = guest.ages_of_children.map(
          (age) => (age.age != null && age.age !== "" ? Number(age.age) : null)
        );
      } else {
        delete params.guests[index].ages_of_children;
      }
    });
  }
  return params;
};

// 文字列になってしまっている値をnumber型にする
export const convertSearchCruiseCabinFormNumericValues = (data: any) => {
  const params = clone(data);
  if (params.guests?.length > 0) {
    params.guests = params.guests.map((guest) => {
      guest.number_of_adult = Number(guest.number_of_adult);
      if (Number(guest.number_of_children) > 0) {
        guest.ages_of_children = guest.ages_of_children
          .filter((child) => child.age != null && child.age !== "")
          .map((child) => ({
            age: Number(child.age),
          }));
      }
      guest.number_of_children = guest.ages_of_children?.length || 0;
      return guest;
    });
  }
  return params;
};

export const formatCruiseDate = (dateString: string, i18n: any) => {
  const date = parseDate(dateString);
  if (isValid(date)) {
    return format(date, i18n.language === "ja" ? "yyyy/MM/dd" : "MMMM d, yyyy");
  }
  return dateString;
};
